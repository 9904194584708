import { StrictMode } from 'react';
import { render } from 'react-dom';
import { DataSharingProvider } from './components/DataSharingContext';

import App from './App';

const rootElement = document.getElementById('root');
render(
	<StrictMode>
		<DataSharingProvider>
			<App />
		</DataSharingProvider>
	</StrictMode>,
	rootElement
);
