import React, { useRef, useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { playerTypes } from './PlayerTypes.js';

const AddPlayerNoteModal = ({ open, handleClose }) => {
  const apiUrl = process.env.REACT_APP_SERVER_URL || "http://localhost:5000";
  //const apiUrl = "http://localhost:5000";
  const fileInputRef = useRef(null);
  const token = localStorage.getItem('token');

  // State for the new input fields
  const [nickname, setNickname] = useState("");
  const [translatedNickname, setTranslatedNickname] = useState("");
  const [descriptiveNickname, setDescriptiveNickname] = useState("");
  const [playertype, setPlayertype] = useState("");

  const [notes, setNotes] = useState([]); // Array of note texts
  const [newNote, setNewNote] = useState(""); // Current note input
  const [selectedFile, setSelectedFile] = useState(null); // Image file
  const [previewUrl, setPreviewUrl] = useState(null); // Preview URL for the image

  // useEffect for managing object URL of the image
  useEffect(() => {
    let objectUrl;
    if (selectedFile) {
      objectUrl = URL.createObjectURL(selectedFile);
      setPreviewUrl(objectUrl);
    } else {
      setPreviewUrl(null);
    }

    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [selectedFile]);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleImageChange(file);
  };

  const handleImageChange = (eventOrFile) => {
    let file;
    if (eventOrFile instanceof File) {
      file = eventOrFile;
    } else {
      file = eventOrFile.target.files[0];
    }

    // File type validation
    if (file && !file.type.startsWith("image/")) {
      alert("Please select an image file");
      return;
    }

    // File size validation (e.g., limit to 5MB)
    if (file && file.size > 5 * 1024 * 1024) {
      alert("File size should be less than 5MB");
      return;
    }

    setSelectedFile(file);
  };

  const handlePaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    for (const item of items) {
      if (item.kind === "file") {
        const file = item.getAsFile();
        handleImageChange(file);
        break;
      }
    }
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
  };

  const handleAddNote = () => {
    if (!newNote.trim()) {
      // Prevent adding empty notes
      return;
    }

    setNotes([...notes, newNote.trim()]);
    setNewNote("");
  };

  const handleRemoveNote = (index) => {
    const updatedNotes = [...notes];
    updatedNotes.splice(index, 1);
    setNotes(updatedNotes);
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    console.log("Atempt to submit");
    // Append the new fields
    formData.append("nickname", nickname);
    formData.append("translatedNickname", translatedNickname);
    formData.append("descriptiveNickname", translatedNickname);
    formData.append("playerType", playertype);

    // Append notes array
    notes.forEach((note, index) => {
      formData.append(`notes[${index}]`, note);
    });

    // Append image if selected
    if (selectedFile) {
      formData.append("image", selectedFile);
    }

    // Submit the formData to your server
    fetch(`${apiUrl}/api/submit-player-note`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          // Reset all fields
          setNickname("");
          setTranslatedNickname("");
          setDescriptiveNickname("");
          setPlayertype("");
          setNotes([]);
          setNewNote("");
          setSelectedFile(null);
          setPreviewUrl(null);
          // Handle success
          handleClose();
        } else {
          // Handle server errors
          alert("Failed to submit player note");
        }
      })
      .catch((error) => {
        // Handle network errors
        alert("An error occurred while submitting the player note");
        console.error(error);
      });
  };
/** */
  const handleCancel = () => {
    console.log("Cancel new note")
    // Reset all fields
    setNickname("");
    setTranslatedNickname("");
    setDescriptiveNickname("");
    setPlayertype("");
    setNotes([]);
    setNewNote("");
    setSelectedFile(null);
    setPreviewUrl(null);

    handleClose()
  }

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{ fontWeight: "bold", fontSize: "1.5rem", color: "primary.main" }}
      >
        New Player Note
      </DialogTitle>
      <DialogContent onPaste={handlePaste}>
        {/* Nickname Input */}
        <TextField
          margin="dense"
          id="nickname"
          label="Nickname"
          type="text"
          fullWidth
          variant="outlined"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
        />

        {/* Translated Nickname Input */}
        <TextField
          margin="dense"
          id="translatedNickname"
          label="Translated Nickname"
          type="text"
          fullWidth
          variant="outlined"
          value={translatedNickname}
          onChange={(e) => setTranslatedNickname(e.target.value)}
        />
        {/* Translated Nickname Input */}
        <TextField
          margin="dense"
          id="descriptiveNickname"
          label="Descriptive Nickname"
          type="text"
          fullWidth
          variant="outlined"
          value={descriptiveNickname}
          onChange={(e) => setDescriptiveNickname(e.target.value)}
        />

        {/* Player Type Select */}
        <FormControl fullWidth margin="dense">
          <InputLabel id="playertype-label">Player Type</InputLabel>
          <Select
            labelId="playertype-label"
            id="playertype"
            value={playertype}
            label="Player Type"
            onChange={(e) => setPlayertype(e.target.value)}
          >
            {playerTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Note Input Field and Add Note Button */}
        <Box display="flex" alignItems="center">
          <TextField
            autoFocus
            margin="dense"
            id="note"
            label="New Player Note"
            type="text"
            fullWidth
            variant="outlined"
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && newNote.trim()) {
                handleAddNote();
              }
            }}
          />
          <Button
            onClick={handleAddNote}
            color="primary"
            variant="contained"
            sx={{ marginLeft: 1, height: "fit-content" }}
            disabled={!newNote.trim()}
          >
            Add Note
          </Button>
        </Box>

        {/* Image Upload Section */}
        <Box
          onClick={handleClick}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          sx={{
            marginTop: "20px",
            padding: "20px",
            border: "2px dashed",
            borderColor: "primary.main",
            borderRadius: "5px",
            textAlign: "center",
            cursor: "pointer",
            bgcolor: "background.paper",
            "&:hover": {
              bgcolor: "action.hover",
            },
          }}
        >
          <Typography variant="body1" color="textSecondary">
            Drag and drop an image here, paste, or click to select
          </Typography>
          {selectedFile && (
            <Box mt={2} position="relative">
              <Typography variant="subtitle1" color="primary">
                Selected File: {selectedFile.name}
              </Typography>
              {/* Display the image preview with remove button */}
              {previewUrl && (
                <Box mt={1} position="relative" display="inline-block">
                  <img
                    src={previewUrl}
                    alt="Preview"
                    style={{ maxWidth: "100%", marginTop: "10px" }}
                  />
                  {/* Remove Image Button */}
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveImage();
                    }}
                    color="secondary"
                    size="small"
                    sx={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      backgroundColor: "rgba(255,255,255,0.7)",
                      "&:hover": {
                        backgroundColor: "rgba(255,255,255,1)",
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <input
          accept="image/*"
          type="file"
          onChange={handleImageChange}
          style={{ display: "none" }}
          ref={fileInputRef}
        />

        {/* List of Added Notes */}
        {notes.length > 0 && (
          <Box mt={4}>
            <Typography variant="h6">Notes:</Typography>
            {notes.map((note, index) => (
              <Box
                key={index}
                mt={2}
                p={2}
                border="1px solid #ccc"
                borderRadius="5px"
                position="relative"
              >
                <Typography variant="body1">{note}</Typography>
                {/* Remove Note Button */}
                <IconButton
                  onClick={() => handleRemoveNote(index)}
                  color="secondary"
                  size="small"
                  sx={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                    backgroundColor: "rgba(255,255,255,0.7)",
                    "&:hover": {
                      backgroundColor: "rgba(255,255,255,1)",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={notes.length === 0}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPlayerNoteModal;
