// src/utils/colorUtils.js

// Function to convert hex color to rgba
export const hexToRgba = (hex, alpha = 1) => {
  // Ensure the hex color starts with '#'
  const sanitizedHex = hex.startsWith('#') ? hex : `#${hex}`;

  // Check if hex is in the correct format (3 or 6 characters)
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(sanitizedHex)) {
    let c = sanitizedHex.substring(1).split('');

    // Convert shorthand hex (e.g., #FFF) to full form (e.g., #FFFFFF)
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }

    const r = parseInt(c[0] + c[1], 16);
    const g = parseInt(c[2] + c[3], 16);
    const b = parseInt(c[4] + c[5], 16);

    // Return the rgba string
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    console.log("Invalid hexcolor")
  }
};
