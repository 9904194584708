import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom"; // Add Redirect
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { alpha, styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Grid, Box } from "@mui/material";
import { AppBar, Toolbar, Button, Typography, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Login from './components/Login'; // Import the Login component
import axios from "axios";
import AddPlayerNoteModal from "./components/player-notes/AddPlayerNoteModal";
import RoutesComponent from "./RoutesComponent";
import { lightBlue } from "@material-ui/core/colors";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.55),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.65),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  color: 'inherit',
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

function App() {
  const [openModal, setOpenModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [inSession, setInSession] = useState(false);
  const [reloadData, setReloadData] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const apiUrl = process.env.REACT_APP_SERVER_URL || "http://localhost:5000";

  // Check session on component mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log()
    if (token) {
      axios.get(`${apiUrl}/api/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => setIsLoggedIn(true))
      .catch(() => setIsLoggedIn(false));
    }
  }, []);
  
  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    // Clear token from localStorage
    localStorage.removeItem('token');
    // Set isLoggedIn state to false
    setIsLoggedIn(false);
  };
  
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    const d = reloadData === 0 ? 1:0;
    setReloadData(d)
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    console.log("Filter changes -> : " + event.target.value);
  };

  const isInSession = () => {
    return inSession;
  }

  const handleSessionClick = () => {
    setInSession(true);
    console.log(`session: ${inSession}`);
  };

  const handleNotesClick = () => {
    setInSession(false);
    console.log(`Notes session: ${inSession}`);
  };


  return (
    <Router>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        {/* Render login if not logged in */}
        {!isLoggedIn ? (
          <Login onLogin={handleLogin} />
        ) : (
          <>
            {/* Top Menu */}
            <AppBar position="fixed">
              <Toolbar sx={{ display: "flex", justifyContent: "space-between", color:"-moz-initial" }}>
                {/* Left side - Title */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h6" noWrap component="div">
                    Player Notes
                  </Typography>
                </Box>

                {/* Center - Search Field */}
                <Box sx={{ flexGrow: 1, mx: 2 }}>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search..."
                      inputProps={{ "aria-label": "search" }}
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </Search>
                </Box>

                {/* Right side - Buttons */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button component={Link} to="/session" variant="outlined" color="inherit" onClick={handleSessionClick}> 
                    Session
                  </Button>
                  <Button component={Link} to="/notes" variant="outlined" color="inherit" onClick={handleNotesClick} sx={{ margin:1}}>
                    Notes
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ fontWeight: 'bold'}}
                    onClick={handleOpenModal}
                  >
                    New Note
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ ml: 2, fontWeight: 'bold' }}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </Box>
              </Toolbar>
            </AppBar>

            <Container maxWidth="large">
              {/* Adjust for AppBar height */}
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Box
                    sx={{
                      marginTop: "85px",
                      marginLeft: "0px", // Ensure no left margin
                      display: "flex", // Use flexbox to control alignment
                      justifyContent: "flex-start", // Align items to the left
                    }}
                  >
                  {/* Pass isLoggedIn to RoutesComponent */}
                  <RoutesComponent isLoggedIn={isLoggedIn} searchQuery={searchQuery} reloadData={reloadData} inSession={inSession} />
                  </Box>
                </Grid>
              </Grid>
            </Container>
            {/* Modal for adding new notes */}
            <AddPlayerNoteModal
              open={openModal}
              handleClose={handleCloseModal}
              // Pass necessary props to the modal
            />
          </>
        )}
      </ThemeProvider>
    </Router>
  );
}

export default App;
