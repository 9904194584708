// Array of player types
export const playerTypes = [
    { value: "newPlayer", label: "New Player" },
    { value: "unknownFish", label: "Unknown fish" },
    { value: "aggressiveFolder", label: "Aggressive folder" },
    { value: "aggressiveCaller", label: "Aggressive caller" },
    { value: "passiveFolder", label: "Passive folder" },
    { value: "passiveCaller", label: "Passive caller" },
    { value: "passiveReg", label: "Reg aggressive" },
    { value: "aggressiveReg", label: "Reg passive" },
    { value: "topReg", label: "Top reg" },
    { value: "whale", label: "Whale" },
  ];
  
  // Mapping of player types to colors
  export const playerTypeColors = {
    newPlayer: "E0E0E0",
    unknownFish: "00CC00",
    aggressiveFolder: "CCE5FF",
    aggressiveCaller: "0000CC",
    passiveFolder: "FFCCFF",
    passiveCaller: "9933FF",
    passiveReg: "FFFF66",
    aggressiveReg: "FFB266",
    topReg: "CC6600",
    whale: "990099",
  };
  
  export const getPlayerTypeLabel = (value) => {
    const playerType = playerTypes.find((type) => type.value === value);
    const hCode = playerTypeColors[value];
//    console.log("Type: " + playerType + " hex: " + hCode)
    return playerType ? playerType.label : 'Unknown';  // Return 'Unknown' if value is not found
  };