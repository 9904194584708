// PlayerNotesPage.js
import React, { useState, useEffect, useRef } from "react";
import PlayerList from "./PlayerList";
import PlayerNoteCard from './PlayerNoteCard';
import { Grid } from '@mui/material';
import PlayerEditPanel from "./PlayerEditPanel"; // New component for editing player
import axios from "axios";
import { Box } from "@mui/material";

const PlayerNotesPage = ({ searchQuery, reloadData, inSession }) => {
  const [count, setCount] = useState(0);
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [playerToEdit, setPlayerToEdit] = useState(null); // Track the player being edited
  const searchTimerRef = useRef(null);
  const token = localStorage.getItem("token");

  const apiUrl = process.env.REACT_APP_SERVER_URL || "http://localhost:5000";

  const increaseCount = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const fetchPlayers = async () => {
    console.log(`Playernotes page: Get all notes. In session: ${inSession}`);
    try {
      let response = null;
      if (inSession) {
        console.log("3 Get players notes in session");
        response = await axios.get(`${apiUrl}/api/session/playerdata`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        console.log("Get all player notes");
        response = await axios.get(`${apiUrl}/api/players`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      if (response && response.data && response.data.length > 0) {
        console.log("4 Setting player data");
        setPlayers(response.data);
        setFilteredPlayers(response.data); // Initialize with all players
      }
    } catch (error) {
      console.error("Error fetching players:", error);
    }
  };

  useEffect(() => {
    fetchPlayers();
  }, [inSession, reloadData]);

  /**
  useEffect(() => {
    //Triggas från modal close i App.js
    increaseCount();
    fetchPlayers();
  }, []);
 */

  useEffect(() => {
    increaseCount();
    if (players.length > 0) {
      executeSearch(searchQuery);
    }
  }, [players]);

  useEffect(() => {
    increaseCount();
    if (searchTimerRef.current) {
      clearTimeout(searchTimerRef.current);
    }
    searchTimerRef.current = setTimeout(() => {
      executeSearch(searchQuery);
    }, 500); // Adjust the debounce delay as needed
    return () => {
      clearTimeout(searchTimerRef.current);
    };
  }, [searchQuery]);

  const executeSearch = (query) => {
    increaseCount();
    //console.log("Execute search " + players + " " + count)
    if (players && players.length > 0) {
      //console.log("Filtering " + players + " " + count)
      const filtered = players.filter((player) => {
        const nickname = player.nickname || "";
        const translatedNickname = player.translatedNickname || "";
        return (
          nickname.toLowerCase().includes(query.toLowerCase()) ||
          translatedNickname.toLowerCase().includes(query.toLowerCase())
        );
      });
      setFilteredPlayers(filtered);
    }
  };

  const handleFetchPlayers = () => {
    fetchPlayers()
  }

  const handleEditPlayer = (player) => {
    setPlayerToEdit(player); // Set the player to be edited
  };

  const handleSavePlayer = (updatedPlayer) => {
    // Handle save functionality here, such as making an API call to update the player
    //console.log('Saving player', updatedPlayer);
    //Save
    const formData = new FormData();

    console.log("Save player, attempt to submit");
    // Append the new fields
    formData.append("nickname", updatedPlayer.nickname);
    formData.append("translatedNickname", updatedPlayer.translatedNickname);
    formData.append("descriptiveNickname", updatedPlayer.translatedNickname);
    formData.append("playerType", updatedPlayer.playertype);

    // Append notes array
    updatedPlayer.notes.forEach((note, index) => {
      formData.append(`notes[${index}]`, note);
    });

    //Handle image in the future

    // Submit the formData to your server
    fetch(`${apiUrl}/api/players/${updatedPlayer._id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedPlayer),
    })
      .then((response) => {
        if (response.ok) {
          console.log(`Updated player ${updatedPlayer}`);
          console.log(`Reload player notes`);
          fetchPlayers();
        } else {
          // Handle server errors
          alert("Failed to submit player note");
        }
      })
      .catch((error) => {
        // Handle network errors
        alert("An error occurred while submitting the player note");
        console.error(error);
      });
    setPlayerToEdit(null); // Close the editor after saving
  };

  const handleCancelPlayerEdit = () => {
    // Handle save functionality here, such as making an API call to update the player
    console.log("Canceled player edit");
    setPlayerToEdit(null); // Close the editor after saving
  };

  /**         
   *
   * <PlayerList players={filteredPlayers} onEditPlayer={handleEditPlayer} />

   */

  return (
    <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
      {/* Player List */}
      <Box sx={{ flex: 1, marginRight: "2x" }}>
        <Grid container spacing={2}>
          {filteredPlayers.map((player) => (
            <Grid
              item
              key={player.id || player._id}
              xs={12}
              sm={6}
              md={4}
              lg={2}
            >
              <PlayerNoteCard player={player} onEditPlayer={handleEditPlayer} inSession={inSession} reFetchPlayers={handleFetchPlayers} />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Edit Panel */}
      {playerToEdit && (
        <Box
          sx={{
            width: "15%",
            maxWidth: "15%",
            height: "100vh",
            position: "sticky",
            right: 0,
          }}
        >
          <PlayerEditPanel
            player={playerToEdit}
            onSave={handleSavePlayer}
            onCancel={handleCancelPlayerEdit}
          />
        </Box>
      )}
    </Box>
  );
};

export default PlayerNotesPage;
