// RoutesComponent.js
import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import PlayerNotesPage from './components/player-notes/PlayerNotesPage';
import Login from './components/Login';

const RoutesComponent = ({ searchQuery, isLoggedIn, inSession, reloadData}) => {
  let routes = useRoutes([
    { path: "/", element: isLoggedIn ? <PlayerNotesPage searchQuery={searchQuery} reloadData={reloadData} inSession={inSession}/> : <Navigate to="/login" />},
    { path: "/notes", element: isLoggedIn ? <PlayerNotesPage searchQuery={searchQuery} reloadData={reloadData} inSession={inSession}/> : <Navigate to="/login" />},
    { path: "/new-note", element: isLoggedIn ? <PlayerNotesPage searchQuery={searchQuery} reloadData={reloadData} inSession={inSession}/> : <Navigate to="/login" /> },
    { path: "/session", element: isLoggedIn ? <PlayerNotesPage searchQuery={searchQuery} reloadData={reloadData} inSession={inSession=true}/> : <Navigate to="/login" /> },
    { path: "/login", element: <Login /> },
    // Add more routes as needed
  ]);

  return routes;
};

export default RoutesComponent;
