import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // New state for confirming password
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState(''); // Error state for password mismatch
  const [emailError, setEmailError] = useState(''); // Error state for email validation

  const navigate = useNavigate();

  // Email validation regex
  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);
  const apiUrl = process.env.REACT_APP_SERVER_URL || "http://localhost:5000";

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(username)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    if (isRegistering && password !== confirmPassword) {
        setError(`Passwords do not match. ${password} ${confirmPassword}`);
      return;
    }
    
    try {
    let token;
      if (isRegistering) {
        // Register new user
        await axios.post(`${apiUrl}/api/auth/register`, { username, password }, { withCredentials: true });
        setIsRegistering(false);
      } else {
        // Login existing user
        const response = await axios.post(`${apiUrl}/api/auth/login`, { username, password }, { withCredentials: true });
        token = response.data.token;
        
        localStorage.setItem('token', token);
        onLogin();
      }
      // Clear errors after successful request
      setError('');
      setEmailError('');

      if (token) {
        navigate('/notes'); // Redirect to /notes
      }
    } catch (err) {
      console.error('Error during authentication:', err);
  
      // If registering, check for specific error
      if (isRegistering && err.response && err.response.status === 400) {
        // Handle the case where the email is already in use
        setError(err.response.data.message || 'Email already in use.');
      } else {
        setError('An error occurred during authentication.');
      }
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h4" sx={{ mb: 3 }}>
        {isRegistering ? 'Register' : 'Login'}
      </Typography>
      
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: '300px',
          padding: '20px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          backgroundColor: '#f9f9f9', // Light gray background for contrast
        }}
      >
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
            if (emailError) setEmailError(''); // Clear error on change
          }}
          fullWidth
          error={Boolean(emailError)} // Show red outline if there's an error
          helperText={emailError} // Display error message
          InputLabelProps={{ shrink: true }} // Always show label
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#000', // Black border color
              },
              '&:hover fieldset': {
                borderColor: '#333', // Darker border on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: '#1976d2', // Primary color on focus
              },
            },
            // Set label color
            '& .MuiInputLabel-root': {
              color: '#000', // Black label color
            },
            '& .Mui-focused': {
              color: '#1976d2', // Label color when focused
            },
            // Set input text color to black
            '& .MuiInputBase-input': {
              color: '#000', // Black input text color
            },
          }}
        />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          InputLabelProps={{ shrink: true }} // Always show label
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#000', // Black border color
              },
              '&:hover fieldset': {
                borderColor: '#333', // Darker border on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: '#1976d2', // Primary color on focus
              },
            },
            // Set label color
            '& .MuiInputLabel-root': {
              color: '#000', // Black label color
            },
            '& .Mui-focused': {
              color: '#1976d2', // Label color when focused
            },
            // Set input text color to black
            '& .MuiInputBase-input': {
              color: '#000', // Black input text color
            },
          }}
        />
         {/* Confirm Password Field for Registration */}
         {isRegistering && (
          <TextField
            label="Confirm Password"
            variant="outlined"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={Boolean(error)} // Show red outline if there's an error
            helperText={error} // Display error message
            fullWidth
            InputLabelProps={{ shrink: true }} // Always show label
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#000', // Black border color
                },
                '&:hover fieldset': {
                  borderColor: '#333', // Darker border on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2', // Primary color on focus
                },
              },
              // Set label color
              '& .MuiInputLabel-root': {
                color: '#000', // Black label color
              },
              '& .Mui-focused': {
                color: '#1976d2', // Label color when focused
              },
              // Set input text color to black
              '& .MuiInputBase-input': {
                color: '#000', // Black input text color
              },
            }}
          />
        )}
        {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}


        <Button type="submit" variant="contained" color="primary" fullWidth>
          {isRegistering ? 'Register' : 'Login'}
        </Button>
      </Box>
{/*
     <Button
        onClick={() => {
          setIsRegistering(!isRegistering);
          setError(''); // Clear any error when switching between login and registration
          setEmailError(''); // Clear email error as well
        }}
        sx={{ mt: 2 }}
        variant="text"
      >
        {isRegistering ? 'Already have an account? Login' : 'New here? Register'}
      </Button>
*/}
    </Box>
  );
};

export default Login;
