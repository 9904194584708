import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  List,
  ListItem,
  Button,
} from "@mui/material";
import axios from 'axios';
import FavoriteIcon from "@mui/icons-material/Favorite";
import { hexToRgba } from '../../utils/colorUtils';  // Import the function
import { playerTypes, playerTypeColors, getPlayerTypeLabel } from "./PlayerTypes.js";


// Function to get the color code
const getColorCode = (playerType) => {
  return playerTypeColors[playerType] || "grey";
};

const PlayerNoteCard = ({ player, onEditPlayer, inSession, reFetchPlayers }) => {

  const token = localStorage.getItem('token'); // Retrieve token from local storage
  const apiUrl = process.env.REACT_APP_SERVER_URL || "http://localhost:5000"; // Your API URL
  
  const colorCode = getColorCode(player.playerType);

  // Construct the image source
  let imageSrc = null;
  const defaultBase64Image =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...";
  if (player.imageBase64) {
    const mimeType = player.imageMimeType || "image/jpeg"; // Default to 'image/jpeg' if MIME type is not provided
    imageSrc = `data:${mimeType};base64,${player.imageBase64}`;
  } else {
    // Set imageSrc to the default image URL
    imageSrc = "./images/default-profile.png";
  }

  const updateOrCreateSession = async (PlayerDataId) => {
    try {
      const response = await axios.put(`${apiUrl}/api/session`, 
        { PlayerDataId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      console.log('Response from server:', response.data);
    } catch (error) {
      console.error('Error updating/creating session:', error.response?.data || error.message);
    }
  };

  
  const removePlayerDataFromSession = async (playerDataId) => {
    try {
      const response = await axios.delete(`${apiUrl}/api/session/playerdata/${playerDataId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Handle successful removal (e.g., update state)
    } catch (error) {
      console.error('Error removing PlayerData from session:', error.response?.data || error.message);
    }
  };

  const handleAddToSessionClick = () => {
    if (inSession) {
      (async () => {
        await removePlayerDataFromSession(player._id);
        reFetchPlayers();
      })();
    } else {
      updateOrCreateSession(player._id);
    }
  }

  const handleEditClick = () => {
    onEditPlayer(player); // Trigger edit for this player
  };

  const playerTypeLabel = getPlayerTypeLabel(player.playerType);

  return (
    <Card
      sx={{
        maxWidth: 355,
        height: 375,
        padding: 1,
        marginLeft: 1,
        marginRight: 1,
      }}
    >
      <Box display="flex" sx={{ maxWidth: 355 }}>
        {/* Player Screenshot */}
        {imageSrc && (
          <Box
            component="img"
            src={imageSrc}
            alt={`${player.translatedNickname} screenshot`}
            sx={{
              width: "155px",
              height: "125px",
              objectFit: "cover",
            }}
          />
        )}
        {/* Color Code Block */}
        <Box
          sx={{
            width: "100px",
            height: "125px",
            marginLeft: "25px",
            display: "flex", // Use flexbox
            flexDirection: "column", // Stack items vertically
            justifyContent: "center", // Center the buttons vertically
            alignItems: "center", // Center the buttons horizontally
            gap: 1, // Add some space between the buttons (optional)
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            size="large"
            onClick={handleAddToSessionClick}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Translucent dark background
              color: "white", // White text for contrast
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.7)", // Darker background on hover
              },
            }}
          >
            Grind
          </Button>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            size="large"
            onClick={handleEditClick}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Translucent dark background
              color: "white", // White text for contrast
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.7)", // Darker background on hover
              },
            }}
          >
            Edit
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: hexToRgba(colorCode, 0.5), // 50% opacity
          width: "100%",
          padding: 1,
        }}
      >
        <Typography
          variant="body2"
          color="primary"
          sx={{ marginBottom: 0 }}
        >
          {playerTypeLabel}
        </Typography>
      </Box>

      <Box p={0}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h9" component="div" color="text.secondary">
              {player.translatedNickname}
            </Typography>

            <Typography variant="body2" color="text.secondary">
              {player.nickname}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {player.descriptiveNickname}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
              {player.clicks}
            </Typography>
            <IconButton>
              <FavoriteIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <CardContent
        sx={{
          border: 0,
          borderBlockStyle: "dotted",
          minHeight: 135,
          maxHeight: 160, // Set a fixed max height
          overflow: "auto", // Enable scrolling when content exceeds max height
        }}
      >
        <List dense sx={{ marginTop: -2, marginLeft: -1 }}>
          {player.notes.map((note, index) => (
            <ListItem key={index} sx={{ p: 0, marginLeft: -1 }}>
              <Typography variant="body2" color="text.primary">
                {note}
              </Typography>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default PlayerNoteCard;