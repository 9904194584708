// PlayerEditPanel.js
import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { playerTypes } from "./PlayerTypes.js";

const PlayerEditPanel = ({ player, onSave, onCancel }) => {
  const [nickname, setNickname] = useState(player.nickname);
  const [translatedNickname, setTranslatedNickname] = useState(
    player.translatedNickname
  );
  const [descriptiveNickname, setDescriptiveNickname] = useState(
    player.descriptiveNickname
  );
  const [playerType, setPlayerType] = useState(player.playerType);
  const [notes, setNotes] = useState(player.notes); // Array of note texts
  const [newNote, setNewNote] = useState(""); // Current note input
  const [selectedFile, setSelectedFile] = useState(null); // Image file
  const [previewUrl, setPreviewUrl] = useState(null); // Preview URL for the image


  const handleSave = () => {
    const updatedPlayer = {
      ...player,
      nickname,
      descriptiveNickname,
      translatedNickname,
      playerType,
      notes,
    };
    onSave(updatedPlayer); // Trigger save
  };

  const handleAddNote = () => {
    if (!newNote.trim()) {
      // Prevent adding empty notes
      return;
    }

    setNotes([...notes, newNote.trim()]);
    setNewNote("");
  };

  const handleRemoveNote = (index) => {
    const updatedNotes = [...notes];
    updatedNotes.splice(index, 1);
    setNotes(updatedNotes);
  };

  const handleCancel = () => {
    onCancel();
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        handleCancel();
      }
    };

    // Add event listener for keydown when the component is mounted
    window.addEventListener("keydown", handleEsc);

    // Cleanup event listener when the component is unmounted
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <Box sx={{ p: 2, borderLeft: "1px solid #ddd", height: "100%" }}>
      <Typography variant="h6">Edit Player</Typography>

      {/* Note Input Field and Add Note Button */}
      <Box display="flex" alignItems="center">
        <TextField
          autoFocus
          margin="dense"
          id="note"
          label="New Player Note"
          type="text"
          fullWidth
          variant="outlined"
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && newNote.trim()) {
              handleAddNote();
            }
          }}
        />
        <Button
          onClick={handleAddNote}
          color="primary"
          variant="contained"
          sx={{ marginLeft: 1, height: "fit-content" }}
          disabled={!newNote.trim()}
        >
          Add Note
        </Button>
      </Box>

      {/* List of Added Notes */}
      {notes.length > 0 && (
        <Box mt={4}>
          <Typography variant="h6">Notes:</Typography>
          {notes.map((note, index) => (
            <Box
              key={index}
              mt={2}
              p={2}
              border="1px solid #ccc"
              borderRadius="5px"
              position="relative"
            >
              <Typography variant="body1">{note}</Typography>
              {/* Remove Note Button */}
              <IconButton
                onClick={() => handleRemoveNote(index)}
                color="secondary"
                size="small"
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  backgroundColor: "rgba(255,255,255,0.7)",
                  "&:hover": {
                    backgroundColor: "rgba(255,255,255,1)",
                  },
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}

      <TextField
        label="Nickname"
        fullWidth
        margin="normal"
        value={nickname}
        onChange={(e) => setNickname(e.target.value)}
      />

      <TextField
        label="Translated Nickname"
        fullWidth
        margin="normal"
        value={translatedNickname}
        onChange={(e) => setTranslatedNickname(e.target.value)}
      />
      <TextField
        label="Descriptive Nickname"
        fullWidth
        margin="normal"
        value={descriptiveNickname}
        onChange={(e) => setDescriptiveNickname(e.target.value)}
      />

      {/* Player Type Select */}
      <FormControl fullWidth margin="dense">
        <InputLabel id="playertype-label">Player Type</InputLabel>
        <Select
          labelId="playertype-label"
          id="playertype"
          value={playerType}
          label="Player Type"
          onChange={(e) => setPlayerType(e.target.value)}
        >
          {playerTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
        onClick={handleSave}
      >
        Save
      </Button>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
        onClick={handleCancel}
      >
        Cancel
      </Button>
    </Box>
  );
};

export default PlayerEditPanel;
